import React, { FC, ReactNode, PointerEvent } from "react";
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/system";

import Button, { ButtonProps } from "./Button";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import Text, { TextVariant } from "./Text";
import { COLORS, SPACINGS } from "../../common/consts";

interface DialogProps extends Omit<MuiDialogProps, "children" | "onClose"> {
  title?: string;
  children: ReactNode;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  onClose?: () => void;
  shouldHideCloseBtn?: boolean;
}

const StyledDialog = styled(MuiDialog)`
  .MuiDialogContent-root {
    padding: ${SPACINGS.lg};
  }

  .MuiDialogActions-root {
    padding: ${SPACINGS.lg};
  }
`;

const DialogHeader = styled("div")<{ isTitleExist: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isTitleExist }) =>
    isTitleExist ? "space-between" : "end"};
  padding: ${SPACINGS.sm};
`;

const Dialog: FC<DialogProps> = ({
  title,
  open,
  children,
  onClose,
  primaryButtonProps,
  secondaryButtonProps,
  shouldHideCloseBtn,
}) => {
  const handleClose = (
    _event: PointerEvent<HTMLDivElement>,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason && reason === "backdropClick" && shouldHideCloseBtn) return;

    if (onClose) {
      onClose();
    }
  };
  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogHeader isTitleExist={!!title}>
        {title && <Text variant={TextVariant.md}>{title}</Text>}
        {!shouldHideCloseBtn && (
          <CloseIcon
            width="18px"
            height="18px"
            onClick={onClose}
            className="cursor-pointer"
            color={COLORS.chartBouncesPrevented}
          />
        )}
      </DialogHeader>
      <DialogContent>{children}</DialogContent>
      <DialogActions className="d-flex align-items-center justify-content-center">
        {primaryButtonProps && (
          <Button
            className="px-3"
            variant={primaryButtonProps.variant}
            onClick={primaryButtonProps.onClick}
          >
            {primaryButtonProps.children}
          </Button>
        )}
        {secondaryButtonProps && (
          <Button
            className="px-3"
            variant={secondaryButtonProps.variant}
            onClick={secondaryButtonProps.onClick}
          >
            {secondaryButtonProps.children}
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  );
};

export default Dialog;
