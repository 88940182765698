import { Timestamp } from "firebase/firestore";
import {
  DbOrganizationSettings,
  OrganizationSettings,
  OrganizationStatus,
} from "./organizations";

export type UID = string;

export enum ProfileType {
  ADMIN = "ADMIN",
  DEFAULT = "DEFAULT",
  LEADEARSHIP = "LEADEARSHIP",
}

export type UserSettings = Pick<
  AppUser,
  "firstName" | "title" | "email" | "profile" | "phoneNumber"
>;

export interface AppUser extends OrganizationSettings {
  uid: UID;
  email: string;
  emailVerified?: boolean;
  firstName?: string;
  lastName?: string;
  title?: string;
  phoneNumber?: string;
  organizationId?: string;
  organizationName?: string;
  organizationStatus?: OrganizationStatus;
  isOutreachAuthorized?: boolean;
  isProspectWebhookCreated?: boolean;
  profile?: ProfileType;
  role?: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}
