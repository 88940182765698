import React, { FC } from "react";
import { FormControl, RadioGroup, RadioGroupProps } from "@mui/material";

const RadioButtonsGroup: FC<RadioGroupProps> = ({
  onChange,
  defaultValue,
  children,
  value,
}) => {
  return (
    <FormControl>
      <RadioGroup defaultValue={defaultValue} onChange={onChange} value={value}>
        {children}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonsGroup;
