import { initializeApp } from "firebase/app";
import {
  getAuth,
  User,
  UserCredential,
  NextOrObserver,
  Unsubscribe as AuthUnsubscribe,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  Unsubscribe,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  connectFirestoreEmulator,
  collection,
  doc,
  DocumentReference,
  getDoc,
  getFirestore,
  setDoc,
  SetOptions,
  DocumentSnapshot,
  DocumentData,
  onSnapshot,
  where,
  query,
  CollectionReference,
  orderBy,
  getDocs,
} from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

import {
  ExtendedEmailStatuses,
  Organization,
  OrganizationStats,
  AccountSettings,
  OutreachEmailStatuses,
  DbOrganizationSettings,
  OrganizationSettings,
  OrgWebhookStatus,
  DbOrganizationTrialDetails,
} from "../types/organizations";
import { AppUser, UID } from "../types/users";
import { getStatsDateRange } from "../common/utils/date-time";
import { removeUndefineds } from "../common/utils/data";
import { firebaseConfig, isDevelopment, isProduction } from "./config";

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseFirestore = getFirestore(firebaseApp);
export const firebaseFunctions = getFunctions(firebaseApp);
firebaseFunctions.region = isProduction ? "us-central1" : "europe-west3";

// --------------------
// Emulators
// --------------------
if (isDevelopment) {
  console.warn("Running in development environment");
  connectAuthEmulator(firebaseAuth, "http://localhost:9099");
  connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
  connectFirestoreEmulator(firebaseFirestore, "localhost", 5002);
}

// --------------------
// Authentication
// --------------------

export const onAuthStateChangedHook = (
  observer: NextOrObserver<User>
): AuthUnsubscribe => onAuthStateChanged(firebaseAuth, observer);

export const signUserUp = (
  email: string,
  password: string
): Promise<UserCredential> =>
  createUserWithEmailAndPassword(firebaseAuth, email, password);

export const signUserIn = (
  email: string,
  password: string
): Promise<UserCredential> =>
  signInWithEmailAndPassword(firebaseAuth, email, password);

export const resetPassword = (email: string): Promise<void> =>
  sendPasswordResetEmail(firebaseAuth, email);

export const signUserOut = async (): Promise<void> => {
  await signOut(firebaseAuth);
};

export const reload = () => {
  window.location.reload();
};

// --------------------
// Functions
// --------------------

const getOutreachAuthUrlFunction = httpsCallable(
  firebaseFunctions,
  "getOutreachAuthUrl"
);
export const getOutreachAuthUrl = async (): Promise<{
  result?: { outreachAuthUrl: string };
  status: number;
}> => {
  const { data } = await getOutreachAuthUrlFunction();
  if (!data) {
    throw new Error("Error getting Outreach connect url");
  }
  return data as { result?: { outreachAuthUrl: string }; status: number };
};

const getMyOrganizationFunction = httpsCallable(
  firebaseFunctions,
  "getMyOrganization"
);
export const getMyOrganization = async (): Promise<{
  result?: DbOrganizationSettings;
  status: number;
}> => {
  const { data } = await getMyOrganizationFunction();
  const response = data as {
    result?: DbOrganizationSettings & {
      trialDetails: DbOrganizationTrialDetails;
    };
    status: number;
  };

  if (!data) {
    throw new Error("Error getting organization");
  }

  return response;
};

const updateOrgSettingsFunction = httpsCallable(
  firebaseFunctions,
  "updateOrgSettings"
);
export const updateOrganizationSettings = async (
  userSettings: AccountSettings
): Promise<{
  result?: Organization;
  status: number;
}> => {
  const { data } = await updateOrgSettingsFunction(userSettings);
  if (!data) {
    throw new Error("Error updating organization");
  }
  return data as { result?: Organization; status: number };
};

export function convertDbOrganizationSettingsToAppOrganizationSettings(
  organizationSettings: DbOrganizationSettings
): OrganizationSettings {
  const { customField, isCustomFieldEnabled, ...rest } = organizationSettings;
  const appOrganizationSettings: OrganizationSettings = {
    isEmailStatusCustomFieldEnabled:
      organizationSettings.isCustomFieldEnabled || false,
    emailStatusCustomField: organizationSettings.customField || null,
    ...rest,
  };
  return appOrganizationSettings;
}

export function listenToEmailsInQueue(
  organizationId: string,
  callback: (emailsInQueue: number) => void
) {
  try {
    const docRef = doc(firebaseFirestore, "stats", organizationId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      const emailsInQueue = doc.data()?.emailsInQueue || 0;
      callback(emailsInQueue);
    });
    return unsubscribe;
  } catch (error) {
    console.error("Error listening to remaining emails", error);
  }
}

const getOrgWebhookStatusFunction = httpsCallable(
  firebaseFunctions,
  "getOrgWebhookStatus"
);
export const getOrgWebhookStatus = async (): Promise<{
  result?: OrgWebhookStatus;
  status: number;
}> => {
  const { data } = await getOrgWebhookStatusFunction();
  if (!data) {
    throw new Error("Error getting organization webhook status");
  }
  return data as { result?: OrgWebhookStatus; status: number };
};

const updateOrgWebhookStatusFunction = httpsCallable(
  firebaseFunctions,
  "updateOrgWebhookStatus"
);
export const updateOrgWebhookStatus = async (
  WebhookStatus: OrgWebhookStatus
): Promise<{
  result?: OrgWebhookStatus;
  status: number;
}> => {
  const { data } = await updateOrgWebhookStatusFunction(WebhookStatus);
  if (!data) {
    throw new Error("Error getting organization webhook status");
  }
  return data as { result?: OrgWebhookStatus; status: number };
};

export function convertAppOrganizationSettingsToDbOrganizationSettings(
  organizationSettings: OrganizationSettings
): DbOrganizationSettings {
  const { isEmailStatusCustomFieldEnabled, emailStatusCustomField, ...rest } =
    organizationSettings;
  const dbOrganizationSettings: DbOrganizationSettings = {
    isCustomFieldEnabled:
      organizationSettings.isEmailStatusCustomFieldEnabled || false,
    customField: organizationSettings.emailStatusCustomField || null,
    ...rest,
  };
  return dbOrganizationSettings;
}

const validateOutreachAuthCodeFunction = httpsCallable(
  firebaseFunctions,
  "validateOutreachAuthCode"
);
export const validateOutreachAuthCode = async (
  outreachAuthCode: string
): Promise<{
  result?: Organization;
  status: number;
}> => {
  const { data } = await validateOutreachAuthCodeFunction(outreachAuthCode);

  if (!data) {
    throw new Error("Error validateOutreachAuthCode");
  }

  return data as { result?: Organization; status: number };
};

const setupProspectWebhookFunction = httpsCallable(
  firebaseFunctions,
  "setupProspectWebhook"
);
export const setupProspectWebhook = async (): Promise<{
  result?: { webhookId: string };
  status: number;
}> => {
  const { data } = await setupProspectWebhookFunction();
  if (!data) {
    throw new Error("Error setting up prospect webhook");
  }
  return data as { result?: { webhookId: string }; status: number };
};

// --------------------
// Firestore
// --------------------

const usersCollection = collection(firebaseFirestore, "users");
const statsCollection = collection(firebaseFirestore, "stats");

const setDocSafely = <T>(
  docRef: DocumentReference<T>,
  data: T,
  options?: SetOptions
) => {
  const sanitized = structuredClone(data) as T;
  removeUndefineds(sanitized);
  return setDoc(docRef, sanitized, options || {});
};

export const getDbUser = async (uid: UID): Promise<AppUser | null> => {
  const dbUser = (await getDoc(doc(usersCollection, uid))).data();
  return (dbUser as AppUser) || null;
};

export const convertDbAccountToAppAccount = (
  dbUser: AppUser | null,
  organization?: Organization
): AppUser | null => {
  const {
    uid,
    email: authEmail,
    emailVerified: authEmailVerified,
  } = firebaseAuth.currentUser || {};

  if (!uid || !authEmail) {
    return null;
  }

  const {
    id: dbOrganizationId,
    name: dbOrganizationName,
    status: dbOrganizationStatus,
    isOutreachAuthorized: dbIsOutreachAuthorized,
    prospectsWebhookId: dbProspectsWebhookId,
    trialDetails: dbTrialDetails,
    isTrial: dbIsTrial,
  } = organization || {};

  const {
    firstName: dbFirstName,
    lastName: dbLastName,
    phoneNumber: dbPhoneNumber,
    profile: dbProfile,
    title: dbTitle,
  } = dbUser || {};

  const appUser: AppUser = {
    uid,
    email: authEmail,
    emailVerified: authEmailVerified || false,
    firstName: dbFirstName,
    lastName: dbLastName,
    phoneNumber: dbPhoneNumber,
    profile: dbProfile,
    title: dbTitle,
    organizationId: dbOrganizationId,
    organizationName: dbOrganizationName,
    organizationStatus: dbOrganizationStatus,
    isOutreachAuthorized: dbIsOutreachAuthorized,
    isProspectWebhookCreated: !!dbProspectsWebhookId,
    trialDetails: dbTrialDetails,
    isTrial: dbIsTrial,
  };

  return appUser || null;
};

export const upsertAppUser = async (user: AppUser): Promise<AppUser> => {
  await setDocSafely(doc(usersCollection, user.uid), user);
  return user;
};

export const updateAppUser = async (
  user: Partial<AppUser>
): Promise<Partial<AppUser>> => {
  await setDocSafely(doc(usersCollection, user.uid), user, { merge: true });
  return user;
};

export const subToRunStats = (
  { organizationId }: { organizationId: string },
  observer: (snapshot: DocumentSnapshot<DocumentData>) => void
): Unsubscribe => {
  const docRef: DocumentReference<DocumentData> = doc(
    statsCollection,
    organizationId
  );

  return onSnapshot<DocumentData>(
    docRef,
    { includeMetadataChanges: false },
    observer
  );
};

type StatsResult = {
  [key: string]: Partial<OrganizationStats>;
};

type Activity = {
  status: OutreachEmailStatuses;
  prospectId: number;
  email?: string;
  didGuess?: boolean;
  optedOut?: boolean;
};

const getUniqueProspectCountsForDateRange = async ({
  allActivity,
  startDate,
  endDate,
}: {
  allActivity: CollectionReference<DocumentData>;
  startDate: Date;
  endDate?: Date;
}) => {
  const q = query(
    allActivity,
    where("time", ">", startDate),
    where("time", "<", endDate ?? new Date()),
    orderBy("time", "desc")
  );
  const docs = await getDocs(q);

  const lastActivityByProspectId: Record<number, Activity> = {};
  docs.forEach((doc) => {
    const data = doc.data() as Activity;
    const { prospectId } = data;
    if (prospectId) {
      lastActivityByProspectId[prospectId] = data;
    }
  });

  const bounceActivities: Activity[] = [];
  const enrichedActivities: Activity[] = [];
  docs.forEach((doc) => {
    const data = doc.data() as Activity;
    if (data.didGuess) {
      enrichedActivities.push(data);
    } else if (data.optedOut) {
      bounceActivities.push(data);
    }
  });

  const countByStatus = Object.values(lastActivityByProspectId).reduce<{
    [key in OutreachEmailStatuses | ExtendedEmailStatuses]: number;
  }>(
    (acc, { status }) => {
      acc[status] = (acc[status] || 0) + 1;

      return acc;
    },
    {} as { [key in OutreachEmailStatuses | ExtendedEmailStatuses]: number }
  );

  countByStatus[ExtendedEmailStatuses.EnrichedByBoring] =
    enrichedActivities.length;
  countByStatus[ExtendedEmailStatuses.BouncesPrevented] =
    bounceActivities.length;
  return countByStatus;
};

export const getRangeStats = async ({
  organizationId,
  startDate: startDateArg,
  endDate: endDateArg,
}: {
  organizationId: string;
  startDate: Date;
  endDate: Date;
}): Promise<StatsResult> => {
  const statsRef: DocumentReference<DocumentData> = doc(
    statsCollection,
    organizationId
  );

  const allActivity = collection(statsRef, "activity");

  const result: StatsResult = {};

  const dateRange = getStatsDateRange(startDateArg, endDateArg);

  const promises = [];

  const getStatsForDateRange = async (
    startDate: Date,
    endDate: Date,
    customKey?: string
  ) => {
    const key = customKey || endDate.toDateString();
    result[key] = {
      [OutreachEmailStatuses.Valid]: 0,
      [OutreachEmailStatuses.Invalid]: 0,
      [OutreachEmailStatuses.Questionable]: 0,
      [OutreachEmailStatuses.NoEmail]: 0,
      [ExtendedEmailStatuses.EnrichedByBoring]: 0,
      [ExtendedEmailStatuses.BouncesPrevented]: 0,
    };

    const data = await getUniqueProspectCountsForDateRange({
      allActivity,
      startDate,
      endDate,
    });

    result[key] = {
      ...result[key],
      ...data,
    };

    result[key].prospectsChecked = Object.entries(OutreachEmailStatuses).reduce(
      (acc, curr) => {
        const [, value] = curr;
        return acc + (result[key][value] || 0);
      },
      0
    );
  };

  promises.push(getStatsForDateRange(startDateArg, endDateArg, "totals"));

  for (const endDate of dateRange) {
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - 1);
    promises.push(getStatsForDateRange(startDate, endDate));
  }

  await Promise.all(promises);
  return result;
};
