import React, { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { signUserOut } from "../services/firebase";
import Button, { ButtonVariants } from "../components/Display/Button";
import FormHelper, { Severity } from "../components/DataInput/FormHelper";
import Text, { TextVariant } from "../components/Display/Text";
import Animation from "../components/Display/Animation";
import { useConnectOutreach } from "../common/hooks/useConnectOutreach";
import { signedInUserAtom } from "../state/atoms";

const ConnectOutreach: FunctionComponent = () => {
  const navigate = useNavigate();
  const signedInUser = useRecoilValue(signedInUserAtom);
  const { isProcessing, url, error, openOutreachAuthUrl } =
    useConnectOutreach();

  useEffect(() => {
    if (signedInUser?.isOutreachAuthorized) {
      navigate("/app/dashboard");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedInUser]);

  return (
    <Animation className="col-12">
      <div className="d-flex h-100 align-items-center justify-content-center m-auto">
        <div className="w-75 w-lg-400px">
          <Text variant={TextVariant.h4} className="mb-3 text-center">
            Connect to your Outreach account
          </Text>
          <div className="mb-3">
            <Button
              fullWidth
              variant={ButtonVariants.brand}
              type="submit"
              disabled={isProcessing || !url}
              onClick={openOutreachAuthUrl}
              loading={isProcessing}
              startIcon={<i className="fa-solid fa-plug" />}
            >
              Connect to Outreach
            </Button>
          </div>
          <Text variant={TextVariant.sm} className="text-center">
            I will continue later,{" "}
            <span onClick={signUserOut} className="btn-link cursor-pointer">
              sign out
            </span>{" "}
            for now.
          </Text>
          <FormHelper helperText={error} severity={Severity.error} />
        </div>
      </div>
    </Animation>
  );
};

export default ConnectOutreach;
