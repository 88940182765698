import {
  MaxValueCustomFieldValidatorParams,
  MinValueCustomFieldValidatorParams,
  UniqueCustomFieldValidatorParams,
  ValidationRule,
} from "../types";

export const validateUniqueCustomField = ({
  isEnabled,
  value,
  otherValues,
}: UniqueCustomFieldValidatorParams): boolean | string => {
  if (
    isEnabled &&
    !!value &&
    !otherValues.every((otherValue) => otherValue !== value)
  ) {
    return "Value can not be the same as a different field";
  } else if (!Number(value) && value !== "") {
    return "Value can not contain letters";
  }
  return true;
};

export const validateCustomFieldMinValue = ({
  isEnabled,
  minValue,
}: MinValueCustomFieldValidatorParams): ValidationRule | undefined => {
  return isEnabled
    ? { value: minValue, message: `Minimum value is ${minValue}` }
    : undefined;
};

export const validateCustomFieldMaxValue = ({
  isEnabled,
  maxValue,
}: MaxValueCustomFieldValidatorParams): ValidationRule | undefined => {
  return isEnabled
    ? { value: maxValue, message: `Maximum value is ${maxValue}` }
    : undefined;
};
