import React, { FunctionComponent } from "react";
import { Grid as MuiGrid } from "@mui/material";
import { styled } from "@mui/system";
import { SPACINGS } from "../../common/consts";
import { GridProps as MuiGridProps } from "@mui/material/Grid";

const CustomMuiGrid = styled(MuiGrid)<{ noPadding?: boolean }>`
  && {
    padding: ${({ noPadding }) => (noPadding ? "none" : SPACINGS.sm)};
  }
`;

type GridProps = Omit<MuiGridProps, "sx">;

interface CustomGridProps extends GridProps {
  noPadding?: boolean;
}

const Grid: FunctionComponent<CustomGridProps> = (props) => {
  return <CustomMuiGrid {...props} />;
};

export default Grid;
