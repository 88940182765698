import React, { FC, FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { styled } from "@mui/system";
import {
  Controller,
  useForm,
  SubmitHandler,
  Control,
  UseFormSetValue,
  FieldErrors,
} from "react-hook-form";

import {
  onboardingFormAtom,
  organizationAtom,
  signedInUserAtom,
} from "../state/atoms";
import {
  convertAppOrganizationSettingsToDbOrganizationSettings,
  convertDbAccountToAppAccount,
  convertDbOrganizationSettingsToAppOrganizationSettings,
  getDbUser,
  getMyOrganization,
  updateOrganizationSettings,
} from "../services/firebase";
import { AppUser, ProfileType } from "../types/users";
import { AccountSettings } from "../types/organizations";
import { BouncePrevention, OnboardingSteps } from "../types/forms";
import { COLORS } from "../common/consts";
import { removeUndefinedAndNull } from "../common/utils/data";
import {
  validateUniqueCustomField,
  validateCustomFieldMinValue,
  validateCustomFieldMaxValue,
} from "../common/utils/form";
import { useConnectOutreach } from "../common/hooks/useConnectOutreach";
import FormHelper, { Severity } from "../components/DataInput/FormHelper";
import RadioButtonsGroup from "../components/Display/RadioButtonsGroup";
import RadioButton from "../components/Display/RadioButton";
import Stepper, { StepProps } from "../components/Display/Stepper";
import NumberInput from "../components/DataInput/NumberInput";
import { ButtonVariants } from "../components/Display/Button";
import Text, { TextVariant } from "../components/Display/Text";

import { ReactComponent as ArrowLeft } from "../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right.svg";
import { ReactComponent as BoringIcon } from "../assets/icons/boring-icon.svg";
import { ReactComponent as OutreachIcon } from "../assets/icons/outreach-icon.svg";

const StyledIconWrapper = styled("div")<{ background: string }>`
  background: ${({ background }) => background};
`;

const StepperWrapper = styled("div")<{ shouldUseDefaultHeight?: boolean }>`
  height: ${({ shouldUseDefaultHeight }) =>
    shouldUseDefaultHeight ? "300px" : "360px"};
  width: 400px;
`;

type FieldControllerPropsBase = {
  control: Control<AccountSettings, unknown>;
  errors: FieldErrors<AccountSettings>;
  setValue: UseFormSetValue<AccountSettings>;
};

type EmailStatusControllerProps = FieldControllerPropsBase & {
  timestampCustomFieldValue?: number | null;
};

type VerificationDateControllerProps = FieldControllerPropsBase & {
  emailStatusCustomFieldValue?: number | null;
};

const SKIP_TEMP_COMPONENT_COUNT = 3;

const EmailStatus: FC<EmailStatusControllerProps> = ({
  control,
  errors,
  timestampCustomFieldValue,
  setValue,
}) => {
  return (
    <div className="d-flex flex-column gap-4 align-items-center">
      <Text variant={TextVariant.sm} className="text-left">
        Choose a custom field for us to mark with each prospect&apos;s email
        status
      </Text>
      <div className="w-40">
        <Controller
          name="organizationSettings.emailStatusCustomField"
          control={control}
          rules={{
            validate: (value) =>
              validateUniqueCustomField({
                isEnabled: true,
                value: value?.toString() || "",
                otherValues: [timestampCustomFieldValue?.toString() || ""],
              }),
            min: validateCustomFieldMinValue({ minValue: 1, isEnabled: true }),
            max: validateCustomFieldMaxValue({
              maxValue: 150,
              isEnabled: true,
            }),
          }}
          render={({ field: { ref, value } }) => {
            return (
              <NumberInput
                label="Email status"
                inputRef={ref}
                value={value}
                onChange={(value: number) => {
                  setValue(
                    "organizationSettings.emailStatusCustomField",
                    value
                  );
                }}
                error={!!errors.organizationSettings?.emailStatusCustomField}
                helperText={
                  errors.organizationSettings?.emailStatusCustomField?.message
                }
              />
            );
          }}
        />
      </div>
    </div>
  );
};

const VerificationDate: FC<VerificationDateControllerProps> = ({
  control,
  errors,
  emailStatusCustomFieldValue,
  setValue,
}) => {
  return (
    <div className="d-flex flex-column gap-4 align-items-center">
      <Text variant={TextVariant.sm} className="text-center">
        Choose a custom field for us to mark with the date each prospect&apos;s
        email was verified
      </Text>
      <div className="w-40">
        <Controller
          name="organizationSettings.timestampCustomField"
          control={control}
          rules={{
            validate: (value) =>
              validateUniqueCustomField({
                isEnabled: true,
                value: value?.toString() || "",
                otherValues: [emailStatusCustomFieldValue?.toString() || ""],
              }),
            min: validateCustomFieldMinValue({ minValue: 1, isEnabled: true }),
            max: validateCustomFieldMaxValue({
              maxValue: 150,
              isEnabled: true,
            }),
          }}
          render={({ field: { ref, value } }) => {
            return (
              <NumberInput
                label="Email status"
                inputRef={ref}
                value={value}
                onChange={(value: number) => {
                  setValue("organizationSettings.timestampCustomField", value);
                }}
                error={!!errors.organizationSettings?.timestampCustomField}
                helperText={
                  errors.organizationSettings?.timestampCustomField?.message
                }
              />
            );
          }}
        />
      </div>
    </div>
  );
};

const Onboarding: FunctionComponent = () => {
  const [onboardingForm, setOnboardingForm] =
    useRecoilState(onboardingFormAtom);
  const [signedInUser, setSignedInUser] = useRecoilState(signedInUserAtom);
  const [organization, setOrganization] = useRecoilState(organizationAtom);

  const [loading, setLoading] = useState(false);
  const [shouldBlockConnectOutreach, setShouldBlockConnectOutreach] =
    useState(false);

  const { isProcessing, url, error, openOutreachAuthUrl } =
    useConnectOutreach();

  const isConnectOutreachLoading =
    shouldBlockConnectOutreach || isProcessing || !url;

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    trigger,
    formState: { errors },
  } = useForm<AccountSettings>({
    defaultValues: {
      userSettings: {
        firstName: signedInUser?.firstName || "",
        title: signedInUser?.title || "",
        profile: signedInUser?.profile || ProfileType.DEFAULT,
        phoneNumber: signedInUser?.phoneNumber || "",
      },
      organizationSettings: {
        timestampCustomField: organization?.timestampCustomField || 26,
        emailStatusCustomField: organization?.emailStatusCustomField || 25,
      },
    },
  });

  const emailStatusCustomFieldValue = watch(
    "organizationSettings.emailStatusCustomField"
  );

  const timestampCustomFieldValue = watch(
    "organizationSettings.timestampCustomField"
  );

  const navigate = useNavigate();

  const navigateToVideos = () => window.open(`https://boringplugins.com/video`, "_blank");
  const navigateToSettings = () => navigate("/app/dashboard");

  const handleSecondaryBtnClick = (shouldSkipStep?: boolean) => {
    if (onboardingForm.currentStep > OnboardingSteps.OutreachAdminPermissions) {
      setOnboardingForm((onboardingForm) => ({
        ...onboardingForm,
        currentStep: onboardingForm.currentStep - (shouldSkipStep ? 2 : 1),
      }));
    }
  };

  const handlePrimaryBtnClick = async (shouldSkipStep?: boolean) => {
    if (onboardingForm.currentStep < OnboardingSteps.WatchVideos) {
      setOnboardingForm((onboardingForm) => ({
        ...onboardingForm,
        currentStep: onboardingForm.currentStep + (shouldSkipStep ? 2 : 1),
        isOutreachAdmin:
          onboardingForm.currentStep ===
          OnboardingSteps.OutreachAdminPermissions
            ? !!shouldSkipStep
            : onboardingForm?.isOutreachAdmin,
      }));
    }
  };

  const onSubmit: SubmitHandler<AccountSettings> = async (data) => {
    if (!signedInUser || !organization) return;

    setLoading(true);

    try {
      const updatedSettings: AccountSettings = {
        ...data,
        organizationSettings:
          convertAppOrganizationSettingsToDbOrganizationSettings({
            ...data.organizationSettings,
            timestampCustomField:
              data.organizationSettings.timestampCustomField,
            emailStatusCustomField:
              data.organizationSettings.emailStatusCustomField,
            isFinishedOnboarding: true,
          }),
      };

      await updateOrganizationSettings(
        removeUndefinedAndNull<AccountSettings>(updatedSettings)
      );

      const [organizationResponse, dbUser] = await Promise.all([
        getMyOrganization(),
        getDbUser(signedInUser.uid),
      ]);

      const updatedUser = convertDbAccountToAppAccount(
        dbUser,
        organizationResponse.result
      );

      setOrganization(
        convertDbOrganizationSettingsToAppOrganizationSettings(
          organizationResponse.result || {}
        )
      );
      setSignedInUser(updatedUser);
      setOnboardingForm((onboardingForm) => ({
        ...onboardingForm,
        currentStep: onboardingForm.currentStep + 1,
      }));
    } catch (error) {
      console.error("Failed to update settings:", error?.toString());
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reset({
      userSettings: {
        firstName: signedInUser?.firstName || "",
        title: signedInUser?.title || "",
        profile: signedInUser?.profile || ProfileType.DEFAULT,
        phoneNumber: signedInUser?.phoneNumber || "",
      },
      organizationSettings: {
        timestampCustomField: organization?.timestampCustomField || 26,
        emailStatusCustomField: organization?.emailStatusCustomField || 25,
      },
    });
  }, [signedInUser, organization, reset]);

  useEffect(() => {
    if (
      signedInUser?.isOutreachAuthorized &&
      onboardingForm.currentStep < OnboardingSteps.EmailStatus
    ) {
      setOnboardingForm((onboardingForm) => ({
        ...onboardingForm,
        currentStep: OnboardingSteps.EmailStatus,
      }));
      return;
    }
  }, [
    onboardingForm.currentStep,
    setOnboardingForm,
    signedInUser?.isOutreachAuthorized,
  ]);

  const OutreachAdminPermissions: FC = () => {
    return (
      <Text variant={TextVariant.md}>
        Do you have admin permissions in Outreach?
      </Text>
    );
  };

  const ContactAdmin: FC = () => {
    return (
      <div className="d-flex flex-column gap-3">
        <Text variant={TextVariant.sm}>
          In order to connect boring to Outreach you need admin privileges in
          Outreach.
        </Text>
        <Text variant={TextVariant.sm}>
          If you don&apos;t have admin access, please contact your Outreach
          admin to connect boring to Outreach for you.
        </Text>
      </div>
    );
  };

  const ConnectOutreach: FC = () => {
    return (
      <>
        <Text variant={TextVariant.md}>Connect boring to Outreach</Text>
        <div className="d-flex w-100 justify-content-center align-items-center">
          <StyledIconWrapper
            className="rounded w-55px h-55px"
            background="#1921FF"
          >
            <BoringIcon
              className="rounded w-55px h-55px"
              color={COLORS.backgroundWhite}
            />
          </StyledIconWrapper>
          <div className="d-flex flex-column justify-content-center align-items-center w-55px h-55px">
            <ArrowLeft />
            <ArrowRight />
          </div>
          <StyledIconWrapper
            className="rounded w-55px h-55px"
            background={COLORS.foregroundBrand}
          >
            <OutreachIcon color={COLORS.foregroundWhite} />
          </StyledIconWrapper>
        </div>
        <FormHelper helperText={error} severity={Severity.error} />
      </>
    );
  };

  const BouncePreventing: FC = () => {
    const handleSelectionChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setOnboardingForm({
        ...onboardingForm,
        bouncePrevention: event.target.value as BouncePrevention,
      });
    };

    return (
      <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-column gap-3">
          <Text variant={TextVariant.h6} className="text-center">
            Bounce prevention
          </Text>
          <Text variant={TextVariant.sm}>
            Choose what should happen when a prospect&apos;s email address is
            invalid.
          </Text>
        </div>
        <div className="w-100">
          <RadioButtonsGroup
            onChange={handleSelectionChange}
            defaultValue={BouncePrevention.AddSequences}
            value={onboardingForm.bouncePrevention}
          >
            <RadioButton
              label="Allow them to be sequenced but skip their email steps"
              value={BouncePrevention.AddSequences}
            />
            <RadioButton
              label="Block them from being added to sequences"
              value={BouncePrevention.BlockSequences}
            />
            <RadioButton
              label="Do nothing"
              value={BouncePrevention.DoNothing}
            />
          </RadioButtonsGroup>
        </div>
      </div>
    );
  };

  const Notice: FC = () => {
    return (
      <div className="d-flex flex-column gap-3">
        <Text variant={TextVariant.sm}>
          Note: the way that boring automatically prevents invalid emails from
          being sent is by enabling email opt-outs for prospects with invalid
          emails.
        </Text>
        <Text variant={TextVariant.sm}>
          By opting them out of email, it allows Outreach to automatically skip
          email steps in their sequences, and prevents reps from accidentally
          sending them an email.
        </Text>
        <Text variant={TextVariant.sm}>
          These opt-outs do not act as global opt-outs do in your CRM. They are
          revertible and do not carry any legal implications.
        </Text>
      </div>
    );
  };

  const WatchVideos: FC = () => {
    return (
      <Text variant={TextVariant.sm}>
        Want to watch a video to see how the plugin works?
      </Text>
    );
  };

  const steps: StepProps[] = [
    {
      component: <OutreachAdminPermissions />,
      primaryButtonProps: {
        onClick: () => handlePrimaryBtnClick(true),
        children: "Yes",
        variant: ButtonVariants.brand,
      },
      secondaryButtonProps: {
        onClick: () => handlePrimaryBtnClick(),
        children: "No",
        variant: ButtonVariants.light,
      },
    },
    {
      component: <ContactAdmin />,
      primaryButtonProps: {
        onClick: () => handleSecondaryBtnClick(),
        children: "I understand",
        variant: ButtonVariants.light,
      },
      backButtonProps: {
        onClick: () => handleSecondaryBtnClick(),
      },
    },
    {
      component: <ConnectOutreach />,
      primaryButtonProps: {
        onClick: () => {
          setShouldBlockConnectOutreach(true);
          openOutreachAuthUrl();
        },
        type: "button",
        children: "Connect",
        variant: ButtonVariants.brand,
        loading: isConnectOutreachLoading,
        disabled: isConnectOutreachLoading,
      },
      backButtonProps: {
        onClick: () => handleSecondaryBtnClick(onboardingForm.isOutreachAdmin),
      },
    },
    {
      component: <BouncePreventing />,
      primaryButtonProps: {
        onClick: () =>
          handlePrimaryBtnClick(
            onboardingForm.bouncePrevention === BouncePrevention.DoNothing
          ),
        children: "Next",
        variant: ButtonVariants.brand,
      },
      backButtonProps: {
        onClick: () => handleSecondaryBtnClick(),
      },
    },
    {
      component: <Notice />,
      primaryButtonProps: {
        onClick: () => handlePrimaryBtnClick(),
        children: "I understand",
        variant: ButtonVariants.light,
      },
      backButtonProps: {
        onClick: () => handleSecondaryBtnClick(),
      },
    },
    {
      component: (
        <EmailStatus
          control={control}
          errors={errors}
          timestampCustomFieldValue={timestampCustomFieldValue}
          setValue={setValue}
        />
      ),
      primaryButtonProps: {
        onClick: () => {
          if (
            emailStatusCustomFieldValue !== organization?.emailStatusCustomField
          ) {
            setValue(
              "organizationSettings.emailStatusCustomField",
              organization?.emailStatusCustomField
            );
          }

          handlePrimaryBtnClick();
        },
        children: "I'll do this later",
        variant: ButtonVariants.light,
      },
      secondaryButtonProps: {
        onClick: async () => {
          const isValid = await trigger(
            "organizationSettings.emailStatusCustomField"
          );

          if (isValid) {
            handlePrimaryBtnClick();
          }
        },
        children: "Next",
        variant: ButtonVariants.brand,
      },
    },
    {
      component: (
        <VerificationDate
          control={control}
          errors={errors}
          emailStatusCustomFieldValue={emailStatusCustomFieldValue}
          setValue={setValue}
        />
      ),
      primaryButtonProps: {
        onClick: () => {
          if (
            timestampCustomFieldValue !== organization?.timestampCustomField
          ) {
            setValue(
              "organizationSettings.timestampCustomField",
              organization?.timestampCustomField
            );

            handlePrimaryBtnClick();
          }
        },
        children: "I'll do this later",
        variant: ButtonVariants.light,
      },
      secondaryButtonProps: {
        onClick: () => handleSubmit(onSubmit),
        children: "Finish",
        variant: ButtonVariants.brand,
        disabled: loading,
      },
      backButtonProps: {
        onClick: () => handleSecondaryBtnClick(),
      },
    },
    {
      component: <WatchVideos />,
      primaryButtonProps: {
        onClick: () => navigateToSettings(),
        children: "I've seen it",
        variant: ButtonVariants.light,
      },
      secondaryButtonProps: {
        onClick: () => {
          navigateToVideos();
          navigateToSettings();
        },
        children: "Watch the video",
        variant: ButtonVariants.brand,
      },
      backButtonProps: {
        onClick: () => handleSecondaryBtnClick(),
      },
    },
  ];

  const shouldUseDefaultHeight =
    onboardingForm.currentStep !== OnboardingSteps.Notice &&
    onboardingForm.currentStep !== OnboardingSteps.BouncePreventing;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex align-items-center justify-content-center w-100 h-100"
    >
      <StepperWrapper shouldUseDefaultHeight={shouldUseDefaultHeight}>
        <Stepper
          steps={steps}
          currentStep={onboardingForm.currentStep}
          onCurrentStepChange={(newStep) =>
            setOnboardingForm({ ...onboardingForm, currentStep: newStep })
          }
        />
      </StepperWrapper>
    </form>
  );
};

export default Onboarding;
