import { FC } from "react";
import { TextVariant } from "../components/Display/Text";
import {
  OutreachEmailStatuses,
  ExtendedEmailStatuses,
  EmailStatuses,
} from "../types/organizations";
import {
  DiamondSvg,
  TriangleSvg,
  SquareSvg,
  CircleSvg,
} from "../components/Display/Shapes";

export const SECONDS = 1000;
export const MINUTES = 60 * SECONDS;
export const HOURS = 60 * MINUTES;
export const DAYS = 24 * HOURS;

export const COLORS = {
  backgroundWhite: "#ffffff",
  backgroundPrimary: "#fefdfc",
  backgroundPrimaryCard: "#ffffff",
  backgroundSecondary: "#f4f4f7",
  backgroundSecondaryCard: "#fafafa",
  backgroundBrand: "#e8eaf1",
  backgroundBrandLight: "#5e5eaf14",
  backgroundBrandLightHover: "#5e5eaf0a",
  backgroundBrandDark: "#4d4b6f",
  backgroundBrandTab: "#f2f2ff",
  backgroundBrandTabHover: "#f0f0f0",
  backgroundBrandTable: "#f9f9fb",
  backgroundSwitch: "#565577",
  backgroundSwitchActive: "#7871f6",
  backgroundSwitchHover: "#4f4bac",
  borderBrandLight: "#5951ff",
  borderDark: "#e0e0e0",
  borderPrimary: "#eeeeee",
  borderSecondary: "#a3a2bd",
  borderTertiary: "#b4b4b4",
  foregroundBrand: "#5e5eaf",
  foregroundBrandLight: "#5951ff",
  foregroundBrandTable: "#4f4c92",
  foregroundPrimary: "#454262",
  foregroundSecondary: "#686781",
  foregroundSecondaryLight: "#a3a2bd",
  foregroundSecondaryDark: "#5d5f63",
  foregroundTertiary: "#444260",
  foregroundWhite: "#ffffff",
  chartBouncesPrevented: "#000000",
  chartEmailInvalid: "#b63d4f",
  chartEmailValid: "#4ca18a",
  chartEmailQuestionable: "#e4995f",
  chartEmailNoEmail: "#696ac0",
  chartEmailEnriched: "#649cd2",
  chartEmailProspectsChecked: "#696ac0",
  outlineSwitchHover: "#f3f2fb",
  outlineInputHover: "#5a5aa3",
  inputLabel: "#5853ff",
  integrationLoading: "#e4995f",
  integrationConnected: "#4ca18a",
  integrationDisconnected: "#b63d4f",
};

export const SHADOWS = {
  small:
    "0rem 0rem 0.0625rem rgba(69, 66, 98, 0.16), 0rem 0.0625rem 0.125rem rgba(69, 66, 98, 0.16)",
  medium: `
  rgba(55, 50, 73, 0.2) 0rem 0.188rem 0.188rem,
  rgba(55, 50, 73, 0.08) 0rem 0.188rem 0.625rem,
  rgba(55, 50, 73, 0.1) 0rem 0.188rem 0.875rem;
`,
  inset: "inset 2px 0px 0px",
  brand: "#5951ff",
};

export const SPACINGS = {
  none: "0rem",
  xs: "0.25rem",
  sm: "0.5rem",
  md: "0.75rem",
  lg: "1rem",
  xl: "1.5rem",
};

export const RADII = {
  none: "0rem",
  xxs: "0.1rem",
  xs: "0.125rem",
  sm: "0.25rem",
  md: "0.5rem",
  lg: "0.75rem",
  xl: "1rem",
  full: "9999px",
};

export const SIZES = {
  switchWidth: "3rem",
  switchHeight: "1.5rem",
  switchThumbWidth: "1rem",
  switchThumbHeight: "1rem",
  progressBar: "1.20rem",
};

export const GAPS = {
  xs: "0.5rem",
  sm: "1rem",
  md: "2rem",
  lg: "3rem",
  xl: "6rem",
  xxl: "8rem",
};

export const SKELETON_SIZES = {
  xs: "0.5rem",
  sm: "1rem",
  md: "2rem",
  lg: "4rem",
  xl: "8rem",
};

export const ICON_SIZES = {
  md: "1.5rem",
};

export const FONT_FAMILIES = {
  titles:
    '"Work Sans", Graphik, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  subtitles: '"Inter-Bold", "Inter", "Inter Placeholder", sans-serif',
  regular:
    '"Roboto Flex", Graphik, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
};

export const FONT_WEIGHTS = {
  extralight: 100,
  light: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

export const FONTS: Record<TextVariant, string> = {
  h1: `${FONT_WEIGHTS.bold} 3rem ${FONT_FAMILIES.titles}`,
  h2: `${FONT_WEIGHTS.bold} 2.5rem ${FONT_FAMILIES.titles}`,
  h3: `${FONT_WEIGHTS.semibold} 2rem ${FONT_FAMILIES.titles}`,
  h4: `${FONT_WEIGHTS.semibold} 1.5rem ${FONT_FAMILIES.subtitles}`,
  h5: `${FONT_WEIGHTS.semibold} 1.25rem ${FONT_FAMILIES.titles}`,
  h6: `${FONT_WEIGHTS.semibold} 1rem ${FONT_FAMILIES.subtitles}`,
  sm: `${FONT_WEIGHTS.light} 0.875rem ${FONT_FAMILIES.regular}`,
  md: `${FONT_WEIGHTS.medium} 1rem ${FONT_FAMILIES.regular}`,
  lg: `${FONT_WEIGHTS.semibold} 1.125rem ${FONT_FAMILIES.regular}`,
  xl: `${FONT_WEIGHTS.semibold} 1.5rem ${FONT_FAMILIES.titles}`,
};

export const StatusColors: Record<EmailStatuses, string> = {
  prospectsChecked: COLORS.chartEmailProspectsChecked,
  [OutreachEmailStatuses.Valid]: COLORS.chartEmailValid,
  [OutreachEmailStatuses.Invalid]: COLORS.chartEmailInvalid,
  [OutreachEmailStatuses.Questionable]: COLORS.chartEmailQuestionable,
  [OutreachEmailStatuses.NoEmail]: COLORS.chartEmailNoEmail,
  [ExtendedEmailStatuses.EnrichedByBoring]: COLORS.chartEmailEnriched,
  [ExtendedEmailStatuses.BouncesPrevented]: COLORS.chartBouncesPrevented,
};

export const ChartLegendLabels: Record<EmailStatuses, string> = {
  prospectsChecked: "Prospects verified",
  [OutreachEmailStatuses.Valid]: "Valid",
  [OutreachEmailStatuses.Invalid]: "Invalid",
  [OutreachEmailStatuses.Questionable]: "Questionable",
  [OutreachEmailStatuses.NoEmail]: "Missing",
  [ExtendedEmailStatuses.EnrichedByBoring]: "Enriched by boring",
  [ExtendedEmailStatuses.BouncesPrevented]: "Bounces prevented",
};

export const ChartValuesShapes: Record<EmailStatuses, FC> = {
  prospectsChecked: CircleSvg,
  [OutreachEmailStatuses.Valid]: DiamondSvg,
  [OutreachEmailStatuses.Invalid]: TriangleSvg,
  [OutreachEmailStatuses.Questionable]: SquareSvg,
  [OutreachEmailStatuses.NoEmail]: CircleSvg,
  [ExtendedEmailStatuses.EnrichedByBoring]: SquareSvg,
  [ExtendedEmailStatuses.BouncesPrevented]: DiamondSvg,
};

export const DEFAULT_CUSTOM_FIELD_IDS = {
  emailStatusCustomFieldId: 150,
  timestampCustomFieldId: 149,
  enrichedCustomFieldId: 148,
};

export const LOCAL_STORAGE_KEYS = {
  outreachAuthCode: "outreachAuthCode",
};
