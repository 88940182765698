import React, { FunctionComponent, useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import { Skeleton } from "@mui/material";
import { css, styled } from "@mui/system";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { AccountSettings } from "../types/organizations";
import { AppUser, ProfileType } from "../types/users";
import { organizationAtom, signedInUserAtom } from "../state/atoms";
import {
  convertDbAccountToAppAccount,
  getDbUser,
  getMyOrganization,
  updateOrganizationSettings,
  signUserOut,
  convertDbOrganizationSettingsToAppOrganizationSettings,
  convertAppOrganizationSettingsToDbOrganizationSettings,
  listenToEmailsInQueue,
  getOrgWebhookStatus,
  updateOrgWebhookStatus,
} from "../services/firebase";
import Card from "../components/Display/Card";
import Button, {
  ButtonSizes,
  ButtonVariants,
} from "../components/Display/Button";
import Text, { TextVariant } from "../components/Display/Text";
import Select, { SelectOption } from "../components/DataInput/Select";
import Switch from "../components/DataInput/Switch";
import Input from "../components/DataInput/Input";
import Grid from "../components/Display/Grid";
import {
  COLORS,
  GAPS,
  RADII,
  SKELETON_SIZES,
  SPACINGS,
} from "../common/consts";
import { removeUndefinedAndNull } from "../common/utils/data";
import {
  validateUniqueCustomField,
  validateCustomFieldMinValue,
  validateCustomFieldMaxValue,
} from "../common/utils/form";
import { DEFAULT_CUSTOM_FIELD_IDS } from "../common/consts";
import { CircleSvg } from "../components/Display/Shapes";
import ProgressBar from "../components/Display/ProgressBar";

const profileOptions: SelectOption[] = [
  {
    value: ProfileType.ADMIN,
    label: "Admin",
  },
  {
    value: ProfileType.DEFAULT,
    label: "Default",
  },
  {
    value: ProfileType.LEADEARSHIP,
    label: "Leadership",
  },
];

interface ShapeColorProps {
  color: string;
}

const ShapeColor = styled("div")<ShapeColorProps>`
  ${({ color }) => css`
    color: ${color};
    transition: color 0.15s ease-in-out;
    margin-bottom: ${RADII.xs};
  `};
`;

const ActiveVerificationsBox = styled("div")`
  border: ${RADII.xxs} solid ${COLORS.borderTertiary};
  border-radius: ${RADII.sm};
  padding: ${SPACINGS.sm};
  width: 100%;
  box-sizing: border-box;
  background-color: ${COLORS.backgroundSecondaryCard};
`;

const IntegrationStatusBox = styled("div")`
  border: ${RADII.xxs} solid ${COLORS.borderTertiary};
  border-radius: ${RADII.sm};
  padding: ${SPACINGS.xl} ${SPACINGS.lg};
  width: 100%;
  box-sizing: border-box;
  background-color: ${COLORS.backgroundSecondaryCard};
`;

const Settings: FunctionComponent = () => {
  const [signedInUser, setSignedInUser] = useRecoilState(signedInUserAtom);
  const [organization, setOrganization] = useRecoilState(organizationAtom);
  const [loading, setLoading] = useState(false);
  const [isIntegrationChecked, setIsIntegrationChecked] = useState<
    boolean | undefined
  >(undefined);
  const [isIntegrationUpdateLoading, setIsIntegrationUpdateLoading] =
    useState(false);
  const [emailsInQueue, setEmailsInQueue] = useState(0);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { isDirty, errors },
  } = useForm<AccountSettings>({
    defaultValues: {
      userSettings: {
        firstName: signedInUser?.firstName || "",
        title: signedInUser?.title || "",
        profile: signedInUser?.profile || ProfileType.DEFAULT,
        phoneNumber: signedInUser?.phoneNumber || "",
      },
      organizationSettings: {
        isEmailStatusCustomFieldEnabled:
          organization?.isEmailStatusCustomFieldEnabled || false,
        emailStatusCustomField: organization?.emailStatusCustomField,
        disableOptOutProspects: !organization?.disableOptOutProspects,
        isTimestampCustomFieldEnabled:
          organization?.isTimestampCustomFieldEnabled || false,
        timestampCustomField: organization?.timestampCustomField,
        isEnrichedCustomFieldEnabled:
          organization?.isEnrichedCustomFieldEnabled || false,
        enrichedCustomField: organization?.enrichedCustomField,
      },
    },
  });
  const onSubmit: SubmitHandler<AccountSettings> = (data) =>
    handleFormSubmit(data);

  const emailStatusCustomFieldValue = watch(
    "organizationSettings.emailStatusCustomField"
  );
  const timestampCustomFieldValue = watch(
    "organizationSettings.timestampCustomField"
  );
  const enrichedCustomFieldValue = watch(
    "organizationSettings.enrichedCustomField"
  );

  const isEmailStatusCustomFieldEnabled = watch(
    "organizationSettings.isEmailStatusCustomFieldEnabled"
  );
  const isTimestampCustomFieldEnabled = watch(
    "organizationSettings.isTimestampCustomFieldEnabled"
  );
  const isEnrichedCustomFieldEnabled = watch(
    "organizationSettings.isEnrichedCustomFieldEnabled"
  );

  const timestampCustomFieldNumberValue =
    Number(timestampCustomFieldValue) || null;
  const enrichedCustomFieldNumberValue =
    Number(enrichedCustomFieldValue) || null;

  const handleFormSubmit = async (data: AccountSettings) => {
    if (!signedInUser || !organization) return;
    setLoading(true);

    try {
      const updatedSettings: AccountSettings = {
        ...data,
        organizationSettings: {
          ...data.organizationSettings,
          disableOptOutProspects:
            !data.organizationSettings.disableOptOutProspects,
          timestampCustomField: isTimestampCustomFieldEnabled
            ? timestampCustomFieldNumberValue
            : null,
          enrichedCustomField: isEnrichedCustomFieldEnabled
            ? enrichedCustomFieldNumberValue
            : null,
        },
      };

      const convertedSettings =
        convertAppOrganizationSettingsToDbOrganizationSettings(
          updatedSettings.organizationSettings
        );

      updatedSettings.organizationSettings = convertedSettings;

      await updateOrganizationSettings(
        removeUndefinedAndNull<AccountSettings>(updatedSettings)
      );

      const [organization, dbUser] = await Promise.all([
        getMyOrganization(),
        getDbUser(signedInUser.uid),
      ]);
      const updatedUser = convertDbAccountToAppAccount(
        dbUser,
        organization.result
      );
      setOrganization(
        convertDbOrganizationSettingsToAppOrganizationSettings(
          organization.result || {}
        )
      );
      setSignedInUser(updatedUser as AppUser);
    } catch (error) {
      console.error("Failed to update settings:", error?.toString());
    } finally {
      setLoading(false);
    }
  };

  const handleIntegrationStatusUpdate = async () => {
    try {
      setIsIntegrationUpdateLoading(true);
      await updateOrgWebhookStatus({ active: !isIntegrationChecked });
      const webhookStatus = await getOrgWebhookStatus();
      setIsIntegrationChecked(webhookStatus.result?.active);
      setIsIntegrationUpdateLoading(false);
      toast.success("Account status updated successfully");
    } catch (error) {
      console.error("Failed to update webhook status:", error?.toString());
      setIsIntegrationUpdateLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = listenToEmailsInQueue(
      organization?.id || "",
      (emailsInQueue) => {
        setEmailsInQueue(emailsInQueue);
      }
    );

    return () => unsubscribe?.();
  }, [emailsInQueue, organization?.id]);

  useEffect(() => {
    reset({
      userSettings: {
        firstName: signedInUser?.firstName || "",
        title: signedInUser?.title || "",
        profile: signedInUser?.profile || ProfileType.DEFAULT,
        phoneNumber: signedInUser?.phoneNumber || "",
      },
      organizationSettings: {
        isEmailStatusCustomFieldEnabled:
          organization?.isEmailStatusCustomFieldEnabled || false,
        emailStatusCustomField: organization?.emailStatusCustomField,
        disableOptOutProspects: !organization?.disableOptOutProspects || false,
        isTimestampCustomFieldEnabled:
          organization?.isTimestampCustomFieldEnabled || false,
        timestampCustomField: organization?.timestampCustomField,
        isEnrichedCustomFieldEnabled:
          organization?.isEnrichedCustomFieldEnabled || false,
        enrichedCustomField: organization?.enrichedCustomField,
      },
    });
  }, [signedInUser, organization, reset]);

  useEffect(() => {
    const updateWebhookStatus = async () => {
      try {
        const webhookStatus = await getOrgWebhookStatus();
        setIsIntegrationChecked(webhookStatus.result?.active);
      } catch (error) {
        console.error("Failed to get webhook status:", error?.toString());
      }
    };

    updateWebhookStatus();
  }, []);

  return (
    <Card className="p-3 w-md-100 w-1040px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" columnGap={GAPS.xxl}>
          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              rowGap={SPACINGS.xl}
            >
              <Grid item className="pt-1 pb-2">
                <Text variant={TextVariant.xl}>Account info</Text>
              </Grid>
              <Grid item>
                <Input
                  label="Name"
                  type="text"
                  {...register("userSettings.firstName")}
                />
              </Grid>
              <Grid item>
                <Input
                  label="Title"
                  type="text"
                  {...register("userSettings.title")}
                />
              </Grid>
              <Grid item>
                <Input
                  label="Organization"
                  type="text"
                  disabled
                  value={signedInUser?.organizationName}
                />
              </Grid>
              <Grid item>
                <Input
                  label="Email"
                  type="text"
                  helperText="Contact support to change your email."
                  disabled
                  value={signedInUser?.email}
                />
              </Grid>
              <Grid item>
                <InputLabel shrink>Profile</InputLabel>
                <Select
                  defaultValue={signedInUser?.profile || ProfileType.DEFAULT}
                  options={profileOptions}
                  {...register("userSettings.profile")}
                />
              </Grid>
              <Grid item>
                <Input
                  label="Phone"
                  type="tel"
                  {...register("userSettings.phoneNumber")}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" rowGap={SPACINGS.xl}>
              <Grid noPadding item className="pt-1 pb-2">
                <Text variant={TextVariant.xl}>Workflows</Text>
              </Grid>

              <Grid container noPadding className="pb-2">
                <Controller
                  control={control}
                  name="organizationSettings.disableOptOutProspects"
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                      label="Opt-out prospects with invalid email"
                    />
                  )}
                />
              </Grid>
              <Grid container noPadding className="pb-2">
                <Controller
                  control={control}
                  name="organizationSettings.isEmailStatusCustomFieldEnabled"
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                      label="Mark a custom field with each prospect’s email status"
                    />
                  )}
                />
              </Grid>
              <Grid container noPadding className="pb-2">
                <Input
                  label="Email status"
                  placeholder={`custom ${DEFAULT_CUSTOM_FIELD_IDS.emailStatusCustomFieldId}`}
                  type="tel"
                  error={!!errors.organizationSettings?.emailStatusCustomField}
                  helperText={
                    errors.organizationSettings?.emailStatusCustomField?.message
                  }
                  disabled={!isEmailStatusCustomFieldEnabled}
                  {...register("organizationSettings.emailStatusCustomField", {
                    validate: (value) =>
                      validateUniqueCustomField({
                        isEnabled: isEmailStatusCustomFieldEnabled,
                        value: value?.toString() || "",
                        otherValues: [
                          timestampCustomFieldValue?.toString() || "",
                          enrichedCustomFieldValue?.toString() || "",
                        ],
                      }),
                    min: validateCustomFieldMinValue({
                      minValue: 1,
                      isEnabled: !!isEmailStatusCustomFieldEnabled,
                    }),
                    max: validateCustomFieldMaxValue({
                      maxValue: 150,
                      isEnabled: !!isEmailStatusCustomFieldEnabled,
                    }),
                  })}
                  required={isEmailStatusCustomFieldEnabled}
                />
              </Grid>
              <Grid container noPadding className="pb-2">
                <Controller
                  control={control}
                  name="organizationSettings.isTimestampCustomFieldEnabled"
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                      label="Mark a custom field with the date each email was verified"
                    />
                  )}
                />
              </Grid>
              <Grid container noPadding className="pb-2">
                <Input
                  label="Verification date"
                  placeholder={`custom ${DEFAULT_CUSTOM_FIELD_IDS.timestampCustomFieldId}`}
                  type="tel"
                  error={!!errors.organizationSettings?.timestampCustomField}
                  helperText={
                    errors.organizationSettings?.timestampCustomField?.message
                  }
                  disabled={!isTimestampCustomFieldEnabled}
                  {...register("organizationSettings.timestampCustomField", {
                    validate: (value) =>
                      validateUniqueCustomField({
                        isEnabled: isTimestampCustomFieldEnabled,
                        value: value?.toString() || "",
                        otherValues: [
                          emailStatusCustomFieldValue?.toString() || "",
                          enrichedCustomFieldValue?.toString() || "",
                        ],
                      }),
                    min: validateCustomFieldMinValue({
                      minValue: 1,
                      isEnabled: !!isTimestampCustomFieldEnabled,
                    }),
                    max: validateCustomFieldMaxValue({
                      maxValue: 150,
                      isEnabled: !!isTimestampCustomFieldEnabled,
                    }),
                  })}
                  required={isTimestampCustomFieldEnabled}
                />
              </Grid>
              {!organization?.isEmailGuessingEnabled && (
                <>
                  <Grid container noPadding className="pb-2">
                    <Controller
                      control={control}
                      name="organizationSettings.isEnrichedCustomFieldEnabled"
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          onChange={(e) => {
                            onChange(e.target.checked);
                          }}
                          label="Mark a custom field with the source each email came from"
                        />
                      )}
                    />
                  </Grid>
                  <Grid container noPadding className="pb-2">
                    <Input
                      label="Email source"
                      placeholder={`custom ${DEFAULT_CUSTOM_FIELD_IDS.enrichedCustomFieldId}`}
                      type="tel"
                      error={!!errors.organizationSettings?.enrichedCustomField}
                      helperText={
                        errors.organizationSettings?.enrichedCustomField
                          ?.message
                      }
                      disabled={!isEnrichedCustomFieldEnabled}
                      {...register("organizationSettings.enrichedCustomField", {
                        validate: (value) =>
                          validateUniqueCustomField({
                            isEnabled: isEnrichedCustomFieldEnabled,
                            value: value?.toString() || "",
                            otherValues: [
                              timestampCustomFieldValue?.toString() || "",
                              emailStatusCustomFieldValue?.toString() || "",
                            ],
                          }),
                        min: validateCustomFieldMinValue({
                          minValue: 1,
                          isEnabled: !!isEnrichedCustomFieldEnabled,
                        }),
                        max: validateCustomFieldMaxValue({
                          maxValue: 150,
                          isEnabled: !!isEnrichedCustomFieldEnabled,
                        }),
                      })}
                      required={isEnrichedCustomFieldEnabled}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" gap={GAPS.xxl}>
          <Grid item className={emailsInQueue > 0 ? "pr-4 pl-3" : "pl-3 pr-1"}>
            <Grid noPadding item className="pb-4 pt-1">
              <Text variant={TextVariant.xl}>Active verifications</Text>
            </Grid>
            <ActiveVerificationsBox
              className={emailsInQueue > 0 ? "p-4" : "p-3"}
            >
              {emailsInQueue === 0 ? (
                <>
                  <Text
                    className="pb-2"
                    color={COLORS.foregroundSecondary}
                    variant={TextVariant.sm}
                  >
                    All of your emails have been verified
                  </Text>
                </>
              ) : (
                <>
                  <Text className="pb-1" variant={TextVariant.h4}>
                    {!isNaN(emailsInQueue) ? emailsInQueue : "N/A"}
                  </Text>
                  <Text
                    className="pb-2"
                    color={COLORS.foregroundSecondary}
                    variant={TextVariant.sm}
                  >
                    emails in queue to be verified
                  </Text>
                </>
              )}

              <ProgressBar
                variant="determinate"
                value={emailsInQueue}
                maxValue={100}
              />
            </ActiveVerificationsBox>
          </Grid>
          <Grid item>
            <Grid container noPadding>
              <Grid noPadding item className="pb-4 pt-1">
                <Text variant={TextVariant.xl}>Integration</Text>
              </Grid>
              <IntegrationStatusBox>
                <Text
                  color={COLORS.foregroundSecondaryDark}
                  className="pb-2"
                  variant={TextVariant.h6}
                >
                  {organization?.name}&apos;s Outreach account
                </Text>
                {isIntegrationChecked === undefined ? (
                  <Skeleton
                    className="pb-4"
                    width={"100%"}
                    height={SKELETON_SIZES.sm}
                  />
                ) : (
                  <Grid container noPadding justifyContent="space-between">
                    <Grid item noPadding>
                      <Grid
                        container
                        noPadding
                        display="flex"
                        alignItems="center"
                      >
                        <>
                          <ShapeColor
                            className="mr-1"
                            color={
                              isIntegrationUpdateLoading
                                ? COLORS.integrationLoading
                                : isIntegrationChecked
                                  ? COLORS.integrationConnected
                                  : COLORS.integrationDisconnected
                            }
                          >
                            <CircleSvg />
                          </ShapeColor>
                          <Text
                            color={COLORS.foregroundSecondary}
                            variant={TextVariant.sm}
                          >
                            {isIntegrationUpdateLoading
                              ? "Loading"
                              : isIntegrationChecked
                                ? "Connected"
                                : "Disconnected"}
                          </Text>
                        </>
                      </Grid>
                    </Grid>
                    <Grid item noPadding>
                      <Switch
                        checked={isIntegrationChecked}
                        onChange={() => handleIntegrationStatusUpdate()}
                        disabled={
                          isIntegrationUpdateLoading ||
                          isIntegrationChecked === undefined
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </IntegrationStatusBox>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" className="pr-5 pt-4">
          <Grid item noPadding className="pr-3">
            <Button
              variant={ButtonVariants.brand}
              size={ButtonSizes.lg}
              type="submit"
              disabled={!isDirty || loading}
              loading={loading}
            >
              {!loading && <Text color="white">Save</Text>}
            </Button>
          </Grid>
          <Grid item noPadding>
            <Button
              variant={ButtonVariants.brand}
              size={ButtonSizes.lg}
              onClick={signUserOut}
              type="button"
            >
              <Text color="white">Sign Out</Text>
            </Button>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

export default Settings;
