import React, { FunctionComponent, ReactNode } from "react";
import Loading from "./Loading";
import { Button as MuiButton } from "@mui/material";
import { styled } from "@mui/system";
import { COLORS, FONTS, RADII, SPACINGS } from "../../common/consts";

export enum ButtonVariants {
  brand = "brand",
  light = "light",
  pill = "pill",
}

export enum ButtonSizes {
  sm = "sm",
  md = "md",
  lg = "lg",
}

const getMuiSize = (size: ButtonSizes): "small" | "medium" | "large" => {
  switch (size) {
    case ButtonSizes.sm:
      return "small";
    case ButtonSizes.md:
      return "medium";
    case ButtonSizes.lg:
      return "large";
    default:
      return "medium";
  }
};

const CustomMuiButton = styled(MuiButton)<{ active?: boolean }>`
  && {
    font: ${FONTS.sm};
    text-transform: none;
    border-radius: ${RADII.full};
    padding: ${SPACINGS.sm} ${SPACINGS.md};
    color: ${({ active }) =>
      active ? COLORS.foregroundSecondaryLight : COLORS.foregroundPrimary};
  }
  &:hover {
    background-color: ${COLORS.backgroundSecondary};
  }
`;

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color"> {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  loading?: boolean;
  variant?: ButtonVariants;
  size?: ButtonSizes;
  fullWidth?: boolean;
  active?: boolean;
}

const Button: FunctionComponent<ButtonProps> = ({
  children,
  className,
  startIcon,
  endIcon,
  loading,
  variant = ButtonVariants.brand,
  size = ButtonSizes.md,
  fullWidth = false,
  active,
  ...rest
}) => {
  return (
    <>
      {variant === ButtonVariants.pill ? (
        <CustomMuiButton
          fullWidth={fullWidth}
          className={className}
          size={getMuiSize(size)}
          startIcon={loading ? <Loading /> : startIcon}
          endIcon={endIcon}
          active={active}
          {...rest}
        >
          {children}
        </CustomMuiButton>
      ) : (
        <button
          className={`${`btn btn-${variant}`} btn-${size} ${
            active ? "active" : ""
          } ${fullWidth ? "w-100" : ""} ${className}`}
          {...rest}
        >
          {loading ? <Loading /> : startIcon} {children} {endIcon}
        </button>
      )}
    </>
  );
};

export default Button;
