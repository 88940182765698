import React, { FunctionComponent, useState, useEffect } from "react";
import Card from "../../components/Display/Card";
import Text, { TextVariant } from "../../components/Display/Text";
import Select from "../../components/DataInput/Select";
import Button, {
  ButtonVariants,
  ButtonSizes,
} from "../../components/Display/Button";
import {
  OrganizationStats,
  OutreachEmailStatuses,
  ExtendedEmailStatuses,
} from "../../types/organizations";
import { MainChartData } from "./MainChart";
import { styled } from "@mui/system";
import { COLORS, SPACINGS } from "../../common/consts";
import { getRangeStats } from "../../services/firebase";
import {
  getRelativeDate,
  formatLongDate,
  generateDynamicTimeframeOptions,
} from "../../common/utils/date-time";
import { ReactComponent as RefreshIcon } from "../../assets/icons/refresh.svg";
import ProspectsTabManager from "./ProspectsTabManager";

export interface TimeframeOption {
  value: string;
  label: string;
  startDate: Date;
  endDate: Date;
}

const timeframeOptions: TimeframeOption[] = [
  {
    value: "this-week",
    label: "This week",
    startDate: getRelativeDate(new Date(), 7),
    endDate: new Date(),
  },
  {
    value: "last-week",
    label: "Last week",
    startDate: getRelativeDate(new Date(), 14),
    endDate: getRelativeDate(new Date(), 7),
  },
  {
    value: "this-month",
    label: "This month",
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
  },
  {
    value: "last-month",
    label: "Last month",
    startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
  },
  ...generateDynamicTimeframeOptions(),
];

const StyledButton = styled(Button)`
  background-color: ${COLORS.backgroundBrand};
  display: inline-block;
  padding: ${SPACINGS.xs};
`;

const StyledRefreshIcon = styled(RefreshIcon)`
  fill: ${COLORS.foregroundBrand};
  width: 1.4rem;
  height: 1.4rem;
`;

const StyledTitle = styled(Text)`
  line-height: 1.43;
`;

interface Props {
  organizationId?: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

const Insights: FunctionComponent<Props> = ({ organizationId, setError }) => {
  const [shouldRefetchStats, setShouldRefetchStats] = useState(true);
  const [selectedTimeframe, setSelectedTimeframe] = useState<TimeframeOption>(
    timeframeOptions[0]
  );
  const [isLoading, setIsLoading] = useState(true);

  const [globalStats, setGlobalStats] = useState<OrganizationStats>({
    prospectsChecked: 0,
    enrichedByBoring: 0,
    bouncesPrevented: 0,
    invalid: 0,
    valid: 0,
    questionable: 0,
    noEmail: 0,
  });

  const [stats, setStats] = useState<MainChartData>(
    Array.from({ length: 7 }, (_, i) => ({
      date: formatLongDate(getRelativeDate(new Date(), 6 - i)),
      prospectsChecked: 0,
      [OutreachEmailStatuses.Valid]: 0,
      [OutreachEmailStatuses.Invalid]: 0,
      [OutreachEmailStatuses.Questionable]: 0,
      [OutreachEmailStatuses.NoEmail]: 0,
      [ExtendedEmailStatuses.EnrichedByBoring]: 0,
      [ExtendedEmailStatuses.BouncesPrevented]: 0,
    }))
  );

  const handleTimeframeChange = (value: TimeframeOption) => {
    setSelectedTimeframe(value);
    setShouldRefetchStats(true);
  };

  useEffect(() => {
    if (organizationId && shouldRefetchStats) {
      const getStats = async () => {
        const { startDate, endDate } = selectedTimeframe;
        setIsLoading(true);

        const rangeStats = await getRangeStats({
          organizationId,
          startDate,
          endDate,
        });

        const {
          prospectsChecked: prospectsCheckedDb = 0,
          valid: validFromDb = 0,
          invalid: invalidFromDb = 0,
          questionable: questionableFromDb = 0,
          enrichedByBoring: enrichedFromDb = 0,
          bouncesPrevented: bouncesPreventedFromDb = 0,
          noEmail: missingFromDb = 0,
        } = rangeStats.totals || {
          prospectsChecked: 0,
          valid: 0,
          invalid: 0,
          questionable: 0,
          noEmail: 0,
          enrichedByBoring: 0,
          bouncesPrevented: 0,
        };

        const globalStats: OrganizationStats = {
          prospectsChecked: prospectsCheckedDb,
          enrichedByBoring: enrichedFromDb,
          valid: validFromDb,
          invalid: invalidFromDb,
          questionable: questionableFromDb,
          noEmail: missingFromDb,
          bouncesPrevented: bouncesPreventedFromDb,
        };

        setGlobalStats(globalStats);
        delete rangeStats["totals"];

        const stats: MainChartData = Object.keys(rangeStats).map((key) => ({
          date: formatLongDate(new Date(key)),
          prospectsChecked: rangeStats[key].prospectsChecked || 0,
          [OutreachEmailStatuses.Valid]: rangeStats[key].valid || 0,
          [OutreachEmailStatuses.Invalid]: rangeStats[key].invalid || 0,
          [OutreachEmailStatuses.Questionable]:
            rangeStats[key].questionable || 0,
          [OutreachEmailStatuses.NoEmail]: rangeStats[key].noEmail || 0,
          [ExtendedEmailStatuses.EnrichedByBoring]:
            rangeStats[key].enrichedByBoring || 0,
          [ExtendedEmailStatuses.BouncesPrevented]:
            rangeStats[key].bouncesPrevented || 0,
        }));

        setStats(stats);
        setShouldRefetchStats(false);
        setIsLoading(false);
      };

      getStats();
    }
  }, [organizationId, shouldRefetchStats, selectedTimeframe]);

  return (
    <div className="d-flex flex-column gap-4">
      <Card>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <StyledTitle className="pb-1" variant={TextVariant.xl}>
              Email verification report
            </StyledTitle>
            <Text variant={TextVariant.sm}>
              See how well your email data tool is performing
            </Text>
          </div>
          <div className="d-flex align-items-center mt-3">
            <Select
              value={selectedTimeframe.value}
              options={timeframeOptions}
              onChange={(e) => {
                const { value } = e.target;
                const selectedOption = timeframeOptions.find(
                  (option) => option.value === value
                );
                if (selectedOption) {
                  handleTimeframeChange(selectedOption);
                }
              }}
            />
            <StyledButton
              variant={ButtonVariants.light}
              size={ButtonSizes.sm}
              className="ms-2"
              onClick={() => setShouldRefetchStats(true)}
              startIcon={<StyledRefreshIcon />}
            />
          </div>
        </div>
      </Card>
      <ProspectsTabManager
        globalStats={globalStats}
        stats={stats}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Insights;
