import React, { FunctionComponent, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useLocalStorage from "../common/hooks/useLocalStorage";
import { LOCAL_STORAGE_KEYS } from "../common/consts";

const OutreachRedirect: FunctionComponent = () => {
  const [, setOutreachAuthCode] = useLocalStorage(
    LOCAL_STORAGE_KEYS.outreachAuthCode,
    ""
  );
  const navigate = useNavigate();

  const { search } = useLocation();

  const code = new URLSearchParams(search).get("code");

  useEffect(() => {
    // Get the code from the URL and close the window
    if (code) {
      setOutreachAuthCode(code);
      window.close();
      setTimeout(() => navigate("/"), 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <div className="d-flex h-100 align-items-center justify-content-center m-auto">
      <div
        className="btn btn-light"
        onClick={() => {
          window.close();
        }}
      >
        <i className="fa-solid fa-times" /> Close
      </div>
    </div>
  );
};

export default OutreachRedirect;
