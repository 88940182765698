import React from "react";
import { Navigate, RouteObject } from "react-router-dom";

import AuthPageContainer from "../components/Layout/AuthPageContainer";
import Signup from "../pages/Auth/Signup";
import Login from "../pages/Auth/Login";
import ResetPassword from "../pages/Auth/ResetPassword";
import ConnectOutreach from "../pages/ConnectOutreach";
import Dashboard from "../pages/Dashboard/Dashboard";
import OutreachRedirect from "../pages/OutreachRedirect";
import Onboarding from "../pages/Onboarding";
import WaitForOnboarding from "../pages/WaitForOnboarding";

const nonAuthenticatedRoutes = [
  {
    path: "/auth",
    element: <AuthPageContainer />,
    children: [
      {
        path: "/auth/signup",
        element: <Signup />,
      },
      {
        path: "/auth/login",
        element: <Login />,
      },
      {
        path: "/auth/reset-password",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "/*",
    element: <Navigate to="/auth/login" replace />,
  },
];

const authenticatedRoutes = [
  {
    path: "/app",
    element: <AuthPageContainer />,
    children: [
      {
        path: "/app/connect-outreach",
        element: <ConnectOutreach />,
      },
      {
        path: "/app/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/app/continue-onboarding",
        element: <WaitForOnboarding />,
      },
      {
        path: "/app/continue-self-onboarding",
        element: <Onboarding />,
      },
    ],
  },
  {
    path: "/oauth/outreach",
    element: <OutreachRedirect />,
  },
  {
    path: "/*",
    element: <Navigate to="/app/continue-onboarding" replace />,
  },
];

export const getRoutes = (signedInUser: boolean): RouteObject[] =>
  signedInUser ? authenticatedRoutes : nonAuthenticatedRoutes;
