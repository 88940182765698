import React, { FC, PropsWithChildren } from "react";
import { styled } from "@mui/system";

import Card from "./Card";
import { GAPS } from "../../common/consts";

const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${GAPS.sm};
`;

const Step: FC<PropsWithChildren> = ({ children }) => {
  return <StyledCard>{children}</StyledCard>;
};

export default Step;
