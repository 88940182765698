import { OrganizationStats } from "../../types/organizations";
import { round, calculatePercentage } from "./number";

export const calculateStatsPercentages = (
  stats: OrganizationStats
): OrganizationStats => {
  const total = stats.prospectsChecked || 0;
  const totalBouncesPrevented = stats.bouncesPrevented || 0;

  return {
    prospectsChecked: total,
    valid: round(calculatePercentage(stats.valid || 0, total)),
    invalid: round(calculatePercentage(stats.invalid || 0, total)),
    questionable: round(calculatePercentage(stats.questionable || 0, total)),
    noEmail: round(calculatePercentage(stats.noEmail || 0, total)),
    enrichedByBoring: round(
      calculatePercentage(stats.enrichedByBoring || 0, stats.invalid || 0)
    ),
    bouncesPrevented: totalBouncesPrevented,
  };
};
