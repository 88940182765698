import React, { FC, ReactNode } from "react";
import { styled } from "@mui/system";
import { SHADOWS } from "../../common/consts";
import { COLORS, RADII, SPACINGS } from "../../common/consts";

export interface TooltipProps {
  open?: boolean;
  content?: ReactNode;
  title?: string;
}

const CustomTooltipWrapper = styled("div")`
  color: ${COLORS.backgroundPrimary};
  padding: ${SPACINGS.md};
  border-radius: ${RADII.md};
  background-color: ${COLORS.backgroundBrandDark};
  box-shadow: ${SHADOWS.medium};
`;

const Tooltip: FC<TooltipProps> = ({ open, title, content }) => {
  return open ? (
    <CustomTooltipWrapper>
      {title && <p className="mb-1">{title}</p>}
      {content}
    </CustomTooltipWrapper>
  ) : null;
};

export default Tooltip;
