import React, { FunctionComponent, ReactNode, useState } from "react";
import Button, { ButtonVariants } from "./Button";
import Animation from "./Animation";
import { styled } from "@mui/system";
import { COLORS, FONTS, FONT_WEIGHTS, SPACINGS } from "../../common/consts";

interface Props {
  options: {
    label: ReactNode;
    value?: string;
    disabled?: boolean;
    children?: ReactNode;
    onClick?: () => void;
  }[];
}

const StyledNavLink = styled(Button)<{ active: boolean }>`
  color: ${({ active }) =>
    active ? COLORS.foregroundBrandLight : COLORS.foregroundBrand};
  background-color: transparent;
  border-radius: ${SPACINGS.xs} ${SPACINGS.xs} 0 0;
  padding: ${SPACINGS.md};
  font: ${FONTS.sm};
  border: none;
  font-weight: ${FONT_WEIGHTS.medium};
  cursor: pointer;

  &:hover {
    color: ${COLORS.foregroundBrand};
    background-color: ${COLORS.backgroundBrandTabHover};
  }

  &:active,
  &.active {
    color: ${COLORS.foregroundBrandLight};
    background-color: ${COLORS.backgroundBrandTab};
  }

  &:focus {
    color: ${COLORS.foregroundBrandLight} !important;
    background-color: ${({ active }) =>
      active
        ? COLORS.backgroundBrandTab
        : COLORS.backgroundBrandTabHover} !important;
    outline: none !important;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

const Tabs: FunctionComponent<Props> = ({ options }) => {
  const [activeTab, setActiveTab] = useState(options[0].value || "");

  return (
    <>
      <div className="px-2">
        <ul className="nav">
          {options.map((option) => (
            <li key={option.value} className="nav-item">
              <StyledNavLink
                active={option.value === activeTab}
                type="button"
                variant={ButtonVariants.light}
                disabled={option.disabled}
                onClick={() => {
                  setActiveTab(option.value || "");
                  option.onClick?.();
                }}
              >
                {option.label}
              </StyledNavLink>
            </li>
          ))}
        </ul>
      </div>
      <Animation key={activeTab}>
        {options.find((option) => option.value === activeTab)?.children || null}
      </Animation>
    </>
  );
};

export default Tabs;
