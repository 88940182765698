import React, { FunctionComponent, HTMLAttributes } from "react";
import { css, styled } from "@mui/system";

import { COLORS, FONTS } from "../../common/consts";

export enum TextVariant {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}

interface TextProps extends HTMLAttributes<HTMLElement> {
  variant?: TextVariant;
}

const Box = styled("div")<TextProps>`
  color: ${({ color }) => color || COLORS.foregroundPrimary};
  ${({ variant }) => css`
    font: ${FONTS[variant || TextVariant.md]};
  `}
`;

const Text: FunctionComponent<TextProps> = ({
  variant = TextVariant.md,
  children,
  className,
  ...rest
}) => {
  return (
    <Box className={`text-${variant} ${className}`} variant={variant} {...rest}>
      {children}
    </Box>
  );
};

export default Text;
