import * as React from "react";
import { FunctionComponent } from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { COLORS, RADII, SIZES } from "../../common/consts";

const BorderLinearProgress = styled(LinearProgress)`
  height: ${SIZES.progressBar};
  border-radius: ${RADII.lg};
  background-color: ${COLORS.backgroundSwitchActive};
  border: ${RADII.xxs} solid ${COLORS.borderTertiary};
  transform: rotateY(180deg);

  .MuiLinearProgress-bar {
    background-color: ${COLORS.backgroundWhite};
    transform: rotateY(180deg);
  }
`;

interface ProgressBarProps extends LinearProgressProps {
  value: number;
  maxValue?: number;
}

const ProgressBar: FunctionComponent<ProgressBarProps> = ({
  value,
  maxValue = 100,
  ...rest
}) => {
  const percentage = maxValue === 0 ? 0 : Math.round((100 / maxValue) * value);

  return (
    <BorderLinearProgress
      value={value > maxValue ? 100 : percentage}
      {...rest}
    />
  );
};

export default ProgressBar;
