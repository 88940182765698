import React, { useState, useEffect, ChangeEvent } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";

interface NumberInputProps extends Omit<TextFieldProps, "value" | "onChange"> {
  value?: number | null;
  onChange: (value: number) => void;
}

const NumberInput: React.ForwardRefRenderFunction<
  HTMLDivElement,
  NumberInputProps
> = ({ value, onChange, disabled, label, ...rest }) => {
  const [localValue, setLocalValue] = useState<number | null>(null);

  useEffect(() => {
    if (value) {
      setLocalValue(value);
    }
  }, [value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const num = parseInt(inputValue, 10);

    setLocalValue(num);
    onChange(num);
  };

  return (
    <TextField
      type="number"
      label={label}
      value={localValue}
      onChange={handleChange}
      disabled={disabled}
      variant="outlined"
      fullWidth
      InputProps={{
        onKeyDown: (e) => {
          const isExistedValue = value !== undefined && value !== null;
          const keyUpOrDown = e.key === "ArrowUp" || e.key === "ArrowDown";

          if (keyUpOrDown && isExistedValue) {
            setLocalValue(value);
          }
        },
      }}
      {...rest}
    />
  );
};

export default NumberInput;
