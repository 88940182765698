import React, { FunctionComponent, ReactNode } from "react";
import { styled } from "@mui/material/styles";
import MuiSwitch, { SwitchProps as MuiSwitchProps } from "@mui/material/Switch";
import Text from "../Display/Text";
import { COLORS, RADII, SIZES, SPACINGS } from "../../common/consts";

const CustomSwitch = styled(MuiSwitch)`
  width: ${SIZES.switchWidth};
  height: ${SIZES.switchHeight};
  padding: ${SPACINGS.none};
  display: flex;
  border-radius: ${RADII.lg};

  &:hover {
    outline: ${RADII.md} solid ${COLORS.outlineSwitchHover};
  }

  .MuiSwitch-switchBase {
    padding: ${SPACINGS.xs};

    &.Mui-checked {
      transform: translateX(${SIZES.switchHeight});
      color: ${COLORS.backgroundWhite};

      & + .MuiSwitch-track {
        opacity: 1;
        background-color: ${COLORS.backgroundSwitchActive};
      }
    }
    &:not(.Mui-checked) {
      & + .MuiSwitch-track {
        opacity: 1;
        background-color: ${COLORS.backgroundSwitch};
      }
      &:hover {
        & + .MuiSwitch-track {
          opacity: 1;
          background-color: ${COLORS.backgroundSwitch};
        }
      }
    }
    &:hover {
      & + .MuiSwitch-track {
        opacity: 1;
        background-color: ${COLORS.backgroundSwitchHover};
      }
    }
  }

  .MuiSwitch-thumb {
    width: ${SIZES.switchThumbWidth};
    height: ${SIZES.switchThumbHeight};
  }
`;

const SwtichContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

interface SwitchProps extends MuiSwitchProps {
  label?: ReactNode;
}

const Switch: FunctionComponent<SwitchProps> = ({ label, ...rest }) => {
  return (
    <SwtichContainer>
      {label && <Text className="mr-4">{label}</Text>}
      <CustomSwitch {...rest} />
    </SwtichContainer>
  );
};

export default Switch;
