import React, { FC, ReactNode } from "react";
import { FormControlLabel, Radio, RadioProps } from "@mui/material";

import { ReactComponent as CheckedIcon } from "../../assets/icons/checked-icon.svg";
import { ReactComponent as UncheckedIcon } from "../../assets/icons/unchecked-icon.svg";
import Text, { TextVariant } from "./Text";
import { COLORS } from "../../common/consts";

export enum RadioButtonSizes {
  sm = "sm",
  md = "md",
  lg = "lg",
}

interface RadioButtonProps extends Omit<RadioProps, "size"> {
  label: ReactNode;
  value: string;
  size?: RadioButtonSizes;
}

const getSize = (size: RadioButtonSizes) => {
  switch (size) {
    case RadioButtonSizes.sm:
      return "1rem";
    case RadioButtonSizes.md:
      return "2rem";
    case RadioButtonSizes.lg:
      return "3rem";
    default:
      return "2rem";
  }
};

const getFontVariant = (size: RadioButtonSizes): TextVariant => {
  switch (size) {
    case RadioButtonSizes.sm:
      return TextVariant.sm;
    case RadioButtonSizes.md:
      return TextVariant.md;
    case RadioButtonSizes.lg:
      return TextVariant.lg;
    default:
      return TextVariant.md;
  }
};

const RadioButton: FC<RadioButtonProps> = ({
  label,
  value,
  size = RadioButtonSizes.sm,
}) => {
  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          disableRipple
          checkedIcon={
            <CheckedIcon
              width={getSize(size)}
              height={getSize(size)}
              color={COLORS.backgroundBrandDark}
            />
          }
          icon={
            <UncheckedIcon
              width={getSize(size)}
              height={getSize(size)}
              color={COLORS.backgroundBrandDark}
            />
          }
        />
      }
      label={<Text variant={getFontVariant(size)}>{label}</Text>}
    />
  );
};

export default RadioButton;
