import { OrganizationSettings } from "./organizations";

export enum BouncePrevention {
  AddSequences = "addSequences",
  BlockSequences = "blockSequences",
  DoNothing = "doNothing",
}

export enum OnboardingSteps {
  OutreachAdminPermissions = 1,
  ContactAdmin = 2,
  ConnectOutreach = 3,
  BouncePreventing = 4,
  Notice = 5,
  EmailStatus = 6,
  VerificationDate = 7,
  WatchVideos = 8,
}

export interface SignupForm {
  name: string;
  organizationName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

export interface onboardingForm extends OrganizationSettings {
  currentStep: OnboardingSteps;
  bouncePrevention: BouncePrevention;
  isOutreachAdmin: boolean;
}

export interface LoginForm {
  email: string;
  password: string;
}

export interface ResetPasswordForm {
  email: string;
}

export interface UserDocument {
  email: string;
  authorizationCode: string;
  lastEmailsRun: string;
}
