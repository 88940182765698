// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeUndefineds = (obj: any) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === "object") removeUndefineds(val);
    else if (val === null || val === undefined) delete obj[key];
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeUndefinedAndNull<T extends Record<string, any>>(
  obj: T
): T {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => value !== undefined && value !== null)
      .map(([key, value]) => [
        key,
        value && typeof value === "object" && !Array.isArray(value)
          ? removeUndefinedAndNull(value)
          : value,
      ])
  ) as T;
}
